<template>
  <nav>
    <v-app-bar-nav-icon 
      v-if="$vuetify.breakpoint.mobile"
      @click.stop="displayed = !displayed" 
      color="#FFFFFF"
      class="blur blur-transparent"
    />
    <v-navigation-drawer
      width="80%"
      v-model="displayed" 
      :right="$vuetify.rtl"
      class="border-radius-lg blur blur-transparent font-weight-bold z-index-sticky fixed-plugin"
      :data-color="sidebarColor"
      :data-theme="sidebarTheme"
      :class="$vuetify.breakpoint.mobile ? 'sidebar-mobile' : 'sidebar-web'"
      style="margin: 16px;"
      app
      floating
      :expand-on-hover="mini"
      >
    <!-- 
      disable-resize-watcher 
      permanent
      fixed
    -->
    <!--height="calc(100% - 0rem)" fixed app floating-->
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title class="title d-flex align-center mb-0">
            <div class="v-navigation-drawer-brand pa-0 d-flex align-center">
              <img
                src="@/assets/img/logo-filmmx.svg"
                class="navbar-brand-img ms-3"
                width="80"
              />
            <!--               
              <v-spacer></v-spacer>
              <v-btn
                v-if="$vuetify.breakpoint.mobile"
                icon
                :ripple="false"
                class="btn-dark-hover px-5"
                :class="textColor"
                small
                @click="$emit('toggleSettingsDrawer', false)"
              >
                <v-icon class="material-icons-round" size="20"> clear </v-icon>
              </v-btn> 
            -->
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
  
      <hr class="horizontal mb-0 light line_gradient_background mx-2"/>
  
      <v-list nav dense>
        <v-list-group
          :ripple="false"
          append-icon="fas fa-angle-down"
          class="pb-1 mx-0"
          active-class="main_gradient_background"
        >
        <!--item-active-->
          <template v-slot:activator>
            <v-avatar size="30" class="my-3 ms-0">
              <img :src="(user.Personal_information.profile_image_url != null) ? user.Personal_information.profile_image_url : 'https://filmdurango-dev.s3.us-west-1.amazonaws.com/resources/avatar.png'" alt="Profile Image" />
            </v-avatar>
  
            <v-list-item-content>
              <v-list-item-title class="ms-2 ps-1 font-weight-light"
              :class="textColor"
              >
                {{ (user.Personal_information.name != null) ? user.Personal_information.complete_name : 'User' }}
                <!-- {{ '[' + user.Personal_information.complete_name + ']' }} -->
              </v-list-item-title>
            </v-list-item-content>
          </template>
  
          <v-list-item
            :ripple="false"
            link
            class="mb-1 no-default-hover border-radius-lg py-2"
            active-class="main_gradient_background"
            v-for="child in userInfo"
            :key="child.title"
            :to="child.link"
            @click="[$emit('openSectionIndex', child.section_type_id)]"
          >
            <v-list-item-content class="ms-2 ps-1" :class="textColor">
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
  
      <hr class="horizontal mb-0 light line_gradient_background mx-2"/>

      <!-- <h6
        class=" text-uppercase text-caption ls-0 font-weight-bolder p-0 mx-4 mt-4 mb-2 ps-2 d-none-mini white-space-nowrap"
        :class="textColor"
      >
        Páginas
      </h6> -->
  
      <v-list nav dense>
        <template v-for="item in itemsSections">
          <v-list-item
            v-bind:key="item.title"
            v-if="item.roles.includes(user.Roles[0])"
            :ripple="false"
            link
            class="mb-1 no-default-hover border-radius-lg py-2"
            active-class="main_gradient_background"
            :to="'/admin/sections/'+item.section_name_es+'/'+item.section_type_id"
          >
            <v-list-item-content class="ms-2 ps-1"
            :class="textColor">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <v-list nav dense
        v-if="checkIfHasAllowedOptions(itemsContents)"
      >
    	  <v-list-group
          :ripple="false"
          append-icon="fas fa-angle-down"
          class="pb-1 mx-0"
          active-class="main_gradient_background"
        >
        	<template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="mb-1 no-default-hover border-radius-lg py-2"
              :class="textColor"
              >
                Contenidos
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="content in itemsContents">
            <v-list-item
              v-if="content.roles.includes(user.Roles[0])"
              :ripple="false"
              link
              class="mb-1 no-default-hover border-radius-lg py-2"
              active-class="main_gradient_background"
              v-bind:key="content.title"
              :to="(content.link) ? content.link : '/admin/sections/'+content.section_name_es+'/'+content.section_type_id"
            >
              <v-list-item-content class="ms-2 ps-1" :class="textColor">
                <v-list-item-title>{{ content.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>


      <v-list nav dense
        v-if="checkIfHasAllowedOptions(itemsPostulations)"
      > 
    	  <v-list-group
          :ripple="false"
          append-icon="fas fa-angle-down"
          class="pb-1 mx-0"
          active-class="main_gradient_background"
        >
        	<template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="mb-1 no-default-hover border-radius-lg py-2"
              :class="textColor"
              >
                Postulaciones
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="content in itemsPostulations">
            <v-list-item
              v-if="content.roles.includes(user.Roles[0])"
              :ripple="false"
              link
              class="mb-1 no-default-hover border-radius-lg py-2"
              active-class="main_gradient_background"
              v-bind:key="content.title"
              :to="content.link"
            >
              <v-list-item-content class="ms-2 ps-1" :class="textColor">
                <v-list-item-title>{{ content.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>



      <v-list nav dense
        v-if="checkIfHasAllowedOptions(itemsManagers)"
      > 
    	  <v-list-group
          :ripple="false"
          append-icon="fas fa-angle-down"
          class="pb-1 mx-0"
          active-class="main_gradient_background"
        >
        	<template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="mb-1 no-default-hover border-radius-lg py-2"
              :class="textColor"
              >
                Administración
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="content in itemsManagers">
            <v-list-item
              v-if="content.roles.includes(user.Roles[0])"
              :ripple="false"
              link
              class="mb-1 no-default-hover border-radius-lg py-2"
              active-class="main_gradient_background"
              v-bind:key="content.title"
              :to="content.link"
            >
              <v-list-item-content class="ms-2 ps-1" :class="textColor">
                <v-list-item-title>{{ content.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>

      <v-card
        class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
      >
      <!--:style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"-->
        <span
          class="mask opacity-8 border-radius-lg"
          :class="`bg-gradient-` + sidebarColor"
        ></span>
      </v-card>
    </v-navigation-drawer>
  </nav>
</template>
<script>
import Api from '@/api/Api';

export default {
  name: "drawer",
  props: {
    showSettingsDrawer: {
      type: Boolean,
      default: false,
    },
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
    textColor: {
      type: String,
      default: 'text-white'
    },
  },
  data: () => ({
    displayed: true,
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    user: {
      Personal_information: {
        complete_name: "  "
      },
      Roles: [
        null
      ]
    },
    userInfo: [
      { title: "Mi perfil", prefix: "MP", link: "/admin/profile_overview"}
    ],
    itemsSections: [
      { 
        title: "Locaciones", 
        prefix: "L", 
        active: false,
        section_type_id: 4,
        section_name: 'location',
        section_name_es: 'locacion',
        roles: [
          'Admininstrator',
          'Location owner'
        ]
      },
      { 
        title: "Incentivos", 
        prefix: "I", 
        active: false,
        section_type_id: 2,
        section_name: 'incentive',
        section_name_es: 'incentivo',
        roles: [
          'Admininstrator'
        ]
      },      
      { 
        title: "Productos", 
        prefix: "PP", 
        active: false,
        section_type_id: 6,
        section_name: 'product',
        section_name_es: 'producto',
        roles: [
          'Admininstrator',
          'Provider'
        ]
      },
      { 
        title: "Servicios", 
        prefix: "PS", 
        active: false,
        section_type_id: 7,
        section_name: 'service',
        section_name_es: 'servicio',
        roles: [
          'Admininstrator',
          'Provider'
        ]
      },
      { 
        title: "Solicitudes de proveedor", 
        prefix: "SP", 
        active: false,
        section_type_id: 14,
        section_name: 'provider_request',
        section_name_es: 'Solicitudes de proveedor',
        roles: [
          'Admininstrator',
          'Producer',
          //'Provider'
        ]
      },
      { 
        title: "Empleos y Capacitación", 
        prefix: "B", 
        active: false,
        section_type_id: 1,
        section_name: 'job',
        section_name_es: 'empleo',
        roles: [
          'Admininstrator',
          'Producer'
        ]
      }
    ],
    itemsContents: [
      { 
        title: "Filmografía", 
        prefix: "F", 
        active: false,
        section_type_id: 5,
        section_name: 'film',
        section_name_es: 'película',
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Noticias", 
        prefix: "N", 
        active: false,
        section_type_id: 3,
        section_name: 'new',
        section_name_es: 'noticia',
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Permisos", 
        prefix: "T", 
        active: false,
        section_type_id: 11,
        section_name: 'permit',
        section_name_es: 'permiso',
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Directorio", 
        prefix: "D", 
        active: false,
        section_type_id: 12,
        section_name: 'collaborator',
        section_name_es: 'miembro',
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Página de inicio", 
        prefix: "CD", 
        active: false,
        link: "/admin/pages_management/1000",
        section_type_id: 10,
        section_name: 'no_name',
        section_name_es: 'Página de inicio',
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Cine en Durango", 
        prefix: "CD", 
        active: false,
        link: "/admin/pages_management/1",
        section_type_id: 8,
        section_name: 'no_name',
        section_name_es: 'Cine en Durango',
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Página de contáctanos", 
        prefix: "US", 
        active: false,
        link: "/admin/pages_management/2",
        section_type_id: 8,
        section_name: 'no_name',
        section_name_es: 'Contáctanos',
        roles: [
          'Admininstrator'
        ]
      },

    ],
    itemsPostulations: [
      { 
        title: "Incentivos", 
        prefix: "P", 
        active: false,
        link: "/admin/applications_management/incentive/2",
        roles: [
          'Admininstrator',
          'Producer'
        ]
      },
      { 
        title: "Ofertas de trabajo", 
        prefix: "P", 
        active: false,
        link: "/admin/applications_management/job_offer/1",
        roles: [
          'Admininstrator',
          'Producer',
          'Person',
          'Crew'
        ]
      },
      { 
        title: "Solicitudes de proveedor", 
        prefix: "P", 
        active: false,
        link: "/admin/applications_management/provider_request/14",
        roles: [
          'Admininstrator',
          'Producer',
          'Provider'
        ]
      },
    ],
    itemsManagers: [
      { 
        title: "Usuarios", 
        prefix: "U", 
        active: false,
        link: "/admin/user_management",
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Empresas", 
        prefix: "EP", 
        active: false,
        link: "/admin/companies_management",
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Contactos", 
        prefix: "CON", 
        active: false,
        link: "/admin/contacts_management",
        roles: [
          'Admininstrator'
        ]
      },
      { 
        title: "Experiencia profesional", 
        prefix: "EXP", 
        active: false,
        link: "/admin/experience_management",
        roles: [
          'Provider',
          'Person',
          'Crew'
        ]
      },
      { 
        title: "Categorías", 
        prefix: "CAT", 
        active: false,
        link: "/admin/categories_management",
        roles: [
          'Admininstrator'
        ]
      },
    ],
  }),
  watch: {
    '$route'() {
      this.getUserInfo();
    }
  },
  methods: {
    getUserInfo: function () {
      Api.get('/auth/logged_user').then(response => {
        this.user = response.data;
      })
      .catch(error => this.errorHandling(error))
    },

    checkIfHasAllowedOptions: function (OptionsArray) {
      let allowedOptions = 0;

      OptionsArray.forEach((element) => {
        if (element.roles.includes(this.user.Roles[0])) {
          allowedOptions = allowedOptions + 1;
        }
      });

      return  allowedOptions > 0;
    },

    listClose(event) {
      let itemsSections;
      let itemsContents;
      let itemsPostulations;
      let itemsManagers;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        itemsSections = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
        itemsContents = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
        itemsPostulations = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
        itemsManagers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (itemsSections != null) {
        for (var i = 0; i < itemsSections.length; i++) {
          itemsSections[i].classList.remove("item-active");
        }
      }

      if (itemsContents != null) {
        for (var index = 0; index < itemsContents.length; index++) {
          itemsContents[index].classList.remove("item-active");
        }
      }

      if (itemsPostulations != null) {
        for (var indexP = 0; indexP < itemsContents.length; indexP++) {
          itemsContents[indexP].classList.remove("item-active");
        }
      }

      if (itemsManagers != null) {
        for (var indexM = 0; indexM < itemsContents.length; indexM++) {
          itemsContents[indexM].classList.remove("item-active");
        }
      }
      

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
  created() {
    this.getUserInfo();
  }
};
</script>
<style scoped>
  .sidebar-web {
    width: 188px !important;
  }

  .sidebar-mobile {
    width: 600px !important;
  }
</style>