// Layouts
import AuthLayout from '../views/CMS/Authentication/Layout/AuthLayout.vue'


// Views
import LandingPage from "../views/CMS/Dashboard/LandingPage.vue";
import InicioPage from "../views/CMS/Dashboard/Inicio.vue"
import AboutUsPage from "../views/CMS/Dashboard/AboutUs.vue"
import Login from "../views/CMS/Authentication/Login.vue";
import Register from "../views/CMS/Authentication/Register.vue";
import Movie from "../views/CMS/Dashboard/Movie.vue";
import Locations from "../views/CMS/Dashboard/Locations.vue";
import Jobs from "../views/CMS/Dashboard/Jobs.vue";
import JobsInformation from "../views/CMS/Dashboard/JobsInformation.vue";
import Suppliers from "../views/CMS/Dashboard/Suppliers.vue";
import SupplierInformation from "../views/CMS/Dashboard/SupplierInformation.vue";
import CrewInformation from "../views/CMS/Dashboard/CrewInformation.vue";
import News from "../views/CMS/Dashboard/News.vue";
import NewsInformation from "../views/CMS/Dashboard/NewInformation.vue";
import Filmography from "../views/CMS/Dashboard/Filmography.vue";
import Incentives from "../views/CMS/Dashboard/Incentives.vue";
import IncentiveInformation from "../views/CMS/Dashboard/Incentive.vue";
import PostulationForm from "../views/CMS/Dashboard/PostulationForm.vue";
import ProductService from "../views/CMS/Dashboard/ProductService.vue";
import Permissions from "../views/CMS/Dashboard/Permissions.vue";
import AboutFilmDurango from "../views/CMS/Dashboard/AboutFilmDurango.vue";


import AdminRoot from "../views/CMS/Admin/MainView.vue";
import ProfileOverview from "../views/CMS/Admin/ProfileOverview.vue";
import EditUserInformation from "../views/CMS/Admin/EditUserInformation.vue";
import EditCompanyInformation from "../views/CMS/Admin/EditCompanyInformation.vue";
import SectionsIndex from "../views/CMS/Admin/SectionsIndex.vue";
import PreviewChangesView from "../views/CMS/Admin/PreviewChangesView.vue";
import NewSection from "../views/CMS/Admin/NewSection.vue";
import ManageAboutUs from "../views/CMS/Admin/AboutUsManagement.vue";
import ManageUsers from "../views/CMS/Admin/UserManagement.vue";
import CreateUser from "../views/CMS/Admin/CreateUser.vue";
import ManageCategories from "../views/CMS/Admin/CategoriesManagement.vue";
import ManageSubcategories from "../views/CMS/Admin/SubcategoriesManagement.vue";
import ManageCompanies from "../views/CMS/Admin/CompaniesManagement.vue";
import ActionsCompanyModal from "../views/CMS/Admin/ActionsCompanyModal.vue";
import ManageExperience from "../views/CMS/Admin/ProffesionalExperienceManagement.vue";
import ActionsExperience from "../views/CMS/Admin/ActionsExperience.vue";

import ManageContacts from "../views/CMS/Admin/ContactsManagement.vue";
import ContactDetails from "../views/CMS/Admin/ContactDetails.vue";

import PostulationDetails from "../views/CMS/Admin/PostulationDetails.vue";
import PostulationsTable from "../views/CMS/Admin/PostulationsTable.vue";
import IncentiveFullInformation from "../views/CMS/Admin/IncentiveFullInformation.vue";
import UserDetails from "../views/CMS/Admin/UserDetails.vue";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
    meta: { requiresAuth: false },
    children: [
      {
        path: "/",
        name: "Inicio",
        component: InicioPage,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/about_us",
        name: "Cine en Durango",
        component: AboutUsPage,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/movie/:id",
        name: "Pelicula informacion",
        component: Movie,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/locations",
        name: "Locaciones",
        component: Locations,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/jobs",
        name: "Trabajo",
        component: Jobs,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/jobsInformation/:job_id",
        name: "Trabajo informacion",
        component: JobsInformation,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/suppliers",
        name: "Proveedores",
        component: Suppliers,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/supplierInformation/:user_id",
        name: "Proveedores informacion",
        component: SupplierInformation,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/crewInformation/:user_id",
        name: "Crew informacion",
        component: CrewInformation,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/news",
        name: "Noticias",
        component: News,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/newInformation/:id",
        name: "Noticias informacion",
        component: NewsInformation,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/filmography",
        name: "Filmografia",
        component: Filmography,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/incentives",
        name: "Incentivos",
        component: Incentives,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/incentiveInformation",
        name: "Incentivos Informacion",
        component: IncentiveInformation,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/incentives/apply_for_incentive/:incentive_id",
        name: "Apply for incentive",
        component: PostulationForm,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/productService/:id/:user_id",
        name: "Product Service",
        component: ProductService,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/permissions",
        name: "Permisos",
        component: Permissions,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      },
      {
        path: "/about-film-durango",
        name: "About Film Durango",
        component: AboutFilmDurango,
        meta: {
          groupName: "Dashboards",
          requiresAuth: false
        },
      }
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    name: "Authentication Login",
    meta: { requiresAuth: false },
    children: [
      {
        path: "/login",
        name: "SignUpCover",
        component: Login,
      },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    name: "Register user",
    meta: { requiresAuth: false },
    children: [
      {
        path: "/register",
        name: "Register",
        component: Register,
      },
    ],
  },
  {
    path: "/admin/profile_overview",
    name: "Administrator index",
    component: AdminRoot,
    meta: { 
      groupName: "Administrator",
      requiresAuth: true,
      name_es: "Información general del perfil"
    },
    children: [
      {
        path: "/admin/profile_overview",
        name: "Profile Overview",
        component: ProfileOverview,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Información general del perfil"
        },
      },
      {
        path: "/admin/profile_overview/edit_information/:user_id",
        name: "Edit Profile Information",
        component: EditUserInformation,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Editar información de perfil"
        },
      },
      {
        path: "/admin/profile_overview/edit_company_information/:user_id",
        name: "Edit Company Information",
        component: EditCompanyInformation,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Editar información de empresa"
        },
      },
      {
        path: "/admin/sections/:section/:section_type_id",
        name: "sections",
        component: SectionsIndex,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Administración de secciones"
        },
      },
      {
        path: "/admin/preview_changes/:section/:section_type_id/:section_id",
        name: "preview section changes",
        component: PreviewChangesView,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Revisar cambios de una sección"
        },
      },
      {
        path: "/admin/sections/:action/:section/:section_type_id",
        name: "New section",
        component: NewSection,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Nueva sección"
        },
      },
      {
        path: "/admin/sections/:action/:section/:section_type_id/:section_id",
        name: "Edit section",
        component: NewSection,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Editar sección"
        },
      },
      {
        path: "/admin/pages_management/:page_id",
        name: "Manage about us page",
        component: ManageAboutUs,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Editar la información de la página"
        },
      },
      {
        path: "/admin/user_management",
        name: "Manage users",
        component: ManageUsers,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Gestión de usuarios"
        },
      },
      {
        path: "/admin/user_details/:user_id",
        name: "User Details",
        component: UserDetails,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Detalles del usuario"
        },
      },
      {
        path: "/admin/user_management/new_user",
        name: "Create new user",
        component: CreateUser,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Crear nuevo usuario"
        },
      },
      {
        path: "/admin/categories_management",
        name: "Section categories",
        component: ManageCategories,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Categorías de secciones"
        },
      },
      {
        path: "/admin/categories_management/subcategory/:category_id",
        name: "Category's subcategories",
        component: ManageSubcategories,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Gestionar subcategorías"
        },
      },
      {
        path: "/admin/companies_management",
        name: "Companies Management",
        component: ManageCompanies,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Gestion de empresas"
        },
      },
      {
        path: "/admin/companies_management/:action/:company_id",
        name: "Actions companies",
        component: ActionsCompanyModal,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Añadir / actualizar una empresa"
        },
      },
      {
        path: "/admin/experience_management",
        name: "Proffesional Experience Management",
        component: ManageExperience,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Gestion de experiencia profesional"
        },
      },
      {
        path: "/admin/contacts_management",
        name: "Contacts Management",
        component: ManageContacts,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Gestion de mensajes de contacto"
        },
      },
      {
        path: "/admin/contact_details/:contact_id",
        name: "Contact Details",
        component: ContactDetails,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Detalles del mensaje de contacto"
        },
      },
      {
        path: "/admin/experience_management/:action/:experience_id",
        name: "Actions experience",
        component: ActionsExperience,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Añadir / actualizar experiencia profesional"
        },
      },
      {
        path: "/admin/applications_management/:application_type/:section_type_id",
        name: "Applications management",
        component: PostulationsTable,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Gestión de postulaciones"
        },
      },
      {
        path: "/admin/application_details/:application_id",
        name: "Postulation Details",
        component: PostulationDetails,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Detalles de postulación"
        },
      },
      {
        path: "/admin/incentive_full_information/:application_id",
        name: "Incentive Complete Information",
        component: IncentiveFullInformation,
        meta: {
          groupName: "Administrator",
          requiresAuth: true,
          name_es: "Información completa del incentivo"
        },
      },
    ],
  },
];


export default routes;